import React from "react"
import SEO from "../components/seo"

const style = {
    height: "100vh",
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
}

const NotFoundPage = () => (
  <div style={style}>
    <SEO title="404: Not found" />
    <h1>NOT FOUND!</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness. :'(</p>
  </div>
)

export default NotFoundPage
