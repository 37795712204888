module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Users\\Abrown\\Documents\\morecode\\gatsby-portfolio\\src\\components\\layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Andrew Brown Web Dev","short_name":"Andrew Brown","description":"This is my portfolio designed by me! Thanks for checking it out!","start_url":"/","background_color":"#0d1f2d","theme_color":"#0d1f2d","display":"standalone","icon":"src/images/icon-big.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d4acfc673b20efbb3f3a0a6dd86ebaeb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
